import { useDeleteAssignAssets } from "@/supabase/jobs";
import { Modal } from "antd";
import React from "react";

interface DeleteAssetsProps {
  isModalOpen: boolean;
  handleCancelIsModal: any;
  modalData: any;
  assetId: any;
  reloadDashboard: any;
  setAssetsData: any;
  schemaId: any;
}

const DeleteAssets: React.FC<DeleteAssetsProps> = ({
  isModalOpen,
  handleCancelIsModal,
  modalData,
  assetId,
  reloadDashboard,
  setAssetsData,
  schemaId,
}) => {
  const { deleteAssignAssets } = useDeleteAssignAssets();
  const handleDeleteAssets = () => {
    deleteAssignAssets(
      schemaId?.schemaId,
      assetId,
      reloadDashboard,
      handleCancelIsModal,
      setAssetsData
    );
  };
  return (
    <div>
      <Modal
        title="Delete Asset"
        open={isModalOpen}
        onOk={handleCancelIsModal}
        onCancel={handleCancelIsModal}
        footer={false}
      >
        <p className="font-medium text-base text-center mt-8">
          Are you sure you want to delete "{modalData?.label}" from application?
        </p>
        <div className="flex gap-4 mt-4">
          <button
            className="bg-blue-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-blue-700"
            onClick={() => handleDeleteAssets()}
          >
            Delete
          </button>
          <button
            className="bg-red-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-red-700"
            onClick={handleCancelIsModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAssets;
