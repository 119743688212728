import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import supabase from "@/supabase";
import {
  LoginWithGoogle,
  LoginWithMicrosoft,
  storeUserData,
} from "@/redux/slices/SignupSlice";
import { ROLE } from "@/utils/enums";
import { Api } from "@/services/interceptor";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "@/redux/slices/LoaderSlice";
import { storeJobs } from "@/redux/slices/JobsSlice";
import { setProfileImage, setSearchText } from "@/redux/slices/UsersSlice";
import { storeFormitizeUsers } from "@/redux/slices/UsersSlice";
import { storeClientData } from "@/redux/slices/ClientsSlice";
import { OpenSideNav } from "@/redux/slices/SignupSlice";
import { storeRequestJobs } from "@/redux/slices/JobFormListSlice";

interface SignUpValues {
  email: string;
  password: string;
  fullName: string;
  australianBusinessName: string,
  australianNumber: string,
  confirmPassword: string,
  checked: boolean
}

interface LoginUserValues {
  email: string;
  password: string;
}

interface UpdateCustomerProfileValues {
  email?: string;
  newPassword?: string;
  avatar_url?: string
}

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signUpUser = async (
    values: SignUpValues,
    setLoader: (loading: boolean) => void,
  ) => {
    setLoader(true);
    const { data: signupData, error } = await supabase.auth.signUp({
      email: values.email,
      password: values.password,
      options: {
        data: {
          name: values.fullName
        }
      }
    });
    if (error) {
      setLoader(false);
      toast.error(error.message);
    } else {
      const { data, error } = await supabase
        .from("users")
        .insert([
          {
            full_name: values.fullName,
            email: values.email,
            role: ROLE.customer,
            aus_number: values.australianNumber,
            abn: values.australianBusinessName,
          },
        ])
        .select();
      if (data) {
        if (data) {
          dispatch(storeUserData({ ...signupData, ...signupData?.user, role: ROLE.customer }));
        }
        toast.success("Sign-up successful!", {
          position: 'top-right', onClose: () => {
          }
        });
      } else {
        toast.error(error.message);
      }
      setLoader(false);

    }
  };

  const loginUser = async (
    values: LoginUserValues,
    setLoader: (loading: boolean) => void,
  ) => {
    setLoader(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: values.email,
      password: values.password,
    });
    if (error) {
      toast.error(error.message);
      setLoader(false);
    } else if (data?.user?.email) {
      const { data: tableUserData, error } = await supabase.from('users').select('*').eq('email', data.user.email)

      if (tableUserData) {
        dispatch(storeUserData({ ...data, role: tableUserData[0].role, ...tableUserData[0] }));
      }
      toast.success("Login successful!");
      setLoader(false);
    } else {
      toast.error("Please confirm your email address");
      setLoader(false);
    }
  };

  const logoutUser = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.error(error.message);
    } else {
      dispatch(storeUserData(null));
      dispatch(LoginWithGoogle(false));
      dispatch(LoginWithMicrosoft(false));
      dispatch(storeUserData(null));
      toast.success("Logged out successfully!");
    }
  };

  const loginWithGoogle = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
    if (error) {
      console.error("Error: ", error.message);
    } else {
      dispatch(LoginWithGoogle(true));
      dispatch(storeUserData(data));
    }
  };

  const loginWithMicrosoft = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "azure",
      options: {
        scopes: "email,profile,openid",
      },
    });
    if (error) {
      // toast.error(error.message);
      return error;
    } else {
      dispatch(LoginWithMicrosoft(true));
      dispatch(storeUserData(data));
      // toast.success("Logged in with Microsoft!");
      return data;
    }
  };

  const UpdateCustomerProfile = async (
    values: UpdateCustomerProfileValues,
    setLoader: (loading: boolean) => void,
    profileImage: any
  ) => {
    setLoader(true);
    const { data: userData, error: userError } = await supabase
      .from("users")
      .select("*")
      .eq("email", values.email)
      .single();

    if (userError || !userData) {
      toast.error("Email does not exist");
      setLoader(false);
      return;
    }

    // If email exists, proceed with the update
    const { data, error } = await supabase.auth.updateUser({
      password: values?.newPassword,

    });

    if (data) {
      dispatch(storeUserData({ ...data, ...userData }));
      toast.success("Profile updated successfully!");
      setLoader(false);
      navigate(-1);
      const { error: updateError } = await supabase
        .from("users")
        .update({
          profile_image_path: values?.avatar_url || profileImage,
        })
        .eq("email", values.email);
    } else {
      toast.error(error?.message);
      setLoader(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await Api.get("/api/get-jobs");
      toast.success("Data fetched successfully!");
    } catch (error) {
      toast.error("Error fetching data: " + error);
    }
  };

  const fetchUserProfileByEmail = async (profileEmail: string) => {
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("email", profileEmail)
      .single(); // `single` will return the first matched row or null

    if (error) {
      console.error("Error fetching user:", error);
      return null;
    } else {
      dispatch(setProfileImage(data?.profile_image_path))
    }
  };

  const deleteUser = async (userId: string, handleCancelDeleteModal: any, synDataAfterDelete: any) => {
    dispatch(showLoader())
    const { error } = await supabase
      .from('users') // Replace 'users' with your table name
      .delete()
      .eq('id', userId);
    if (error) {
      dispatch(hideLoader())
      handleCancelDeleteModal()
    } else {
      synDataAfterDelete()
      dispatch(hideLoader())
      handleCancelDeleteModal()
    }
  };



  return {
    signUpUser,
    loginUser,
    logoutUser,
    loginWithGoogle,
    loginWithMicrosoft,
    UpdateCustomerProfile,
    fetchData,
    fetchUserProfileByEmail,
    deleteUser
  };
};

export { useAuth };
