import { Modal } from "antd";
import React from "react";
import { toast } from "react-toastify";

interface CopyToClipboardProps {
  openCopyModal: boolean;
  handleCancelCopyModal: () => void;
  modalData: string | null;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  openCopyModal,
  handleCancelCopyModal,
  modalData,
}) => {
  const handleCopy = () => {
    if (modalData) {
      navigator.clipboard.writeText(modalData).then(
        () => {
          toast.success("Copied to clipboard!");
        },
        (err) => {
          toast.error("Failed to copy: ", err);
        }
      );
    }
  };
  return (
    <Modal
      open={openCopyModal}
      onOk={handleCancelCopyModal}
      onCancel={handleCancelCopyModal}
      footer={false}
      title="Copy To Clipboard"
    >
      <div className="flex items-center justify-between mt-8 gap-2">
        <input
          className="font-semibold focus:outline-none border-1 rounded-s-lg p-2 w-10/12"
          type="text"
          value={modalData || ""}
        />

        <button
          onClick={handleCopy}
          className="copy-to-clipboard whitespace-nowrap font-bold shadow-sm rounded-r-lg p-2 px-3 bg-blue-500 text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:bg-blue-700 focus:shadow-sm focus:shadow-outline"
        >
          Copy Url
        </button>
      </div>
    </Modal>
  );
};

export { CopyToClipboard };
