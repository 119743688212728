import { OpenSideNav } from "@/redux/slices/SignupSlice";
import { setSearchText } from "@/redux/slices/UsersSlice";
import { RootState, useAppSelector } from "@/redux/store/Store";
import { useAuth } from "@/supabase/auth";
import { AppLogoSidebar, Dashboard } from "@/utils/Images";
import {
  FileTextOutlined,
  LeftCircleOutlined,
  LogoutOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import { usefetchRequestJob } from "@/supabase/jobs";
import { ROLE } from "@/utils/enums";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logoutUser } = useAuth();
  // const { fetchRequestJobs } = usefetchRequestJob();
  const [requestJobListing, setRequestJobListing] = useState([]);
  const location = useLocation()?.pathname;
  const role = useAppSelector((state) => state.Authlogin?.data?.role);

  const isSidebar = useSelector((state: RootState) => state.Authlogin?.sideNav);

  useEffect(() => {
    dispatch(setSearchText(null));
  }, [location, dispatch]);
  useEffect(() => {
    // fetchRequestJobs(setRequestJobListing);
  }, []);

  return (
    <div
      // className="bg-custom-bg h-screen w-72 pt-6 pl-4 pr-6 duration-500 absolute top-0"
      className={`bg-custom-bg h-screen w-72 pt-6 pl-4 pr-6 duration-500 z-299 fixed top-0 ${
        isSidebar ? "translate-x-0" : "-translate-x-full"
      }`}
      //   style={{ left: !isSideBarOpen ? -260 : 0 }}
    >
      <div className="w-full flex items-center justify-end p-4 pt-0 pr-0">
        <LeftCircleOutlined
          className="cursor-pointer text-white text-2xl"
          onClick={() => dispatch(OpenSideNav(false))}
        />
      </div>
      <div className="felx gap-4 flex items-center justify-center bg-white rounded-lg p-1.5 pl-0">
        <img src={AppLogoSidebar} alt="" className="ml-1 w-12" />
        <p className="font-semibold text-base text-custom-bg">
          Smart Solutions Australia Gateway
        </p>
      </div>
      <div>
        <p className="text-slate-400 font-semibold text-sm mb-4 pl-4 mt-20">
          Menu
        </p>
      </div>
      <div>
        <div
          className={
            location.includes("jobs")
              ? "h-10 px-4 py-2 bg-sidebar-content-bg flex items-center gap-2 cursor-pointer"
              : "h-10 px-4 py-2 flex items-center gap-2 cursor-pointer"
          }
          onClick={() => {
            navigate("jobs");
            dispatch(OpenSideNav(false));
          }}
        >
          <img src={Dashboard} alt="" className="w-4" />
          <p className="font-medium text-base text-sidebar-content-text">
            Jobs
          </p>
        </div>
        {/* <div
          className={
            location.includes("jobs")
              ? "h-10 px-4 py-2 bg-sidebar-content-bg flex items-center gap-2 cursor-pointer"
              : "h-10 px-4 py-2 flex items-center gap-2 cursor-pointer"
          }
          onClick={() => navigate("jobs")}
        >
          <CopyOutlined className="w-4 text-white" />
          <p className="font-medium text-base text-sidebar-content-text">
            Jobs
          </p>
        </div> */}
        {role != ROLE.customer && role != ROLE.staff && (
          <div
            className={
              location.includes("customers")
                ? "h-10 px-4 py-2 bg-sidebar-content-bg flex items-center gap-2 cursor-pointer"
                : "h-10 px-4 py-2 flex items-center gap-2 cursor-pointer"
            }
            onClick={() => {
              navigate("customers");
              dispatch(OpenSideNav(false));
            }}
          >
            <UserAddOutlined className="w-4 text-white" />
            <p className="font-medium text-base text-sidebar-content-text">
              Customers
            </p>
          </div>
        )}
        {role != ROLE.customer && role != ROLE.staff && (
          <div
            className={
              location.includes("staff")
                ? "h-10 px-4 py-2 bg-sidebar-content-bg flex items-center gap-2 cursor-pointer"
                : "h-10 px-4 py-2 flex items-center gap-2 cursor-pointer"
            }
            onClick={() => {
              navigate("staff");
              dispatch(OpenSideNav(false));
            }}
          >
            <UsergroupAddOutlined className="w-4 text-white" />
            <p className="font-medium text-base text-sidebar-content-text">
              Staff
            </p>
          </div>
        )}
        {role != ROLE.staff && (
          <div
            className={
              location.includes("job-request")
                ? "h-10 px-4 py-2 bg-sidebar-content-bg flex items-center gap-2 cursor-pointer"
                : "h-10 px-4 py-2 flex items-center gap-2 cursor-pointer"
            }
            onClick={() => {
              navigate("job-request");
              dispatch(OpenSideNav(false));
            }}
          >
            <FormOutlined className="w-4 text-white" />
            <p className="font-medium text-base text-sidebar-content-text">
              Job Request
            </p>
          </div>
        )}
        <div
          className={
            location.includes("shared-documents")
              ? "h-10 px-4 py-2 bg-sidebar-content-bg flex items-center gap-2 cursor-pointer"
              : "h-10 px-4 py-2 flex items-center gap-2 cursor-pointer"
          }
          onClick={() => {
            navigate("shared-documents");
            dispatch(OpenSideNav(false));
          }}
        >
          <FileTextOutlined className="w-4 text-white" />
          <p className="font-medium text-base text-sidebar-content-text">
            Shared Documents
          </p>
        </div>
        <div
          className={
            location.includes("profile")
              ? "sm:hidden h-10 px-4 py-2 bg-sidebar-content-bg flex items-center gap-2 cursor-pointer"
              : "sm:hidden h-10 px-4 py-2 flex items-center gap-2 cursor-pointer"
          }
          onClick={() => {
            navigate("profile");
            dispatch(OpenSideNav(false));
          }}
        >
          <UserAddOutlined className="w-4 text-white" />
          <p className="font-medium text-base text-sidebar-content-text">
            My Profile
          </p>
        </div>

        <div
          className="sm:hidden h-10 px-4 py-2 w-full flex items-center gap-2 cursor-pointer absolute bottom-0 left-0 bg-red-600 justify-center"
          onClick={() => logoutUser()}
        >
          <LogoutOutlined className="w-4 text-white" />
          <p className="font-medium text-base text-sidebar-content-text">
            Logout
          </p>
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
