import { createSlice } from "@reduxjs/toolkit";

interface AssetType {
    schemaTitle: string;
    name: string;
    id: string,
    schemaID: string;
}

// Define the type for the slice state
interface StoreAssetsState {
    assets: AssetType[];
}

const initialState: StoreAssetsState = {
    assets: [],
};

const storeAssetsSlice = createSlice({
    name: "storeAssetsSlice",
    initialState,
    reducers: {
        storeAssets: (state, actions) => {
            state.assets = actions.payload;
        },
    },
});

export const { storeAssets } = storeAssetsSlice.actions;
export default storeAssetsSlice.reducer;
