import { storeUserData } from "@/redux/slices/SignupSlice";
import { RootState } from "@/redux/store/Store";
import supabase from "@/supabase";
import { ROLE } from "@/utils/enums";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PublicLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoginWithGoogle = useSelector(
    (state: RootState) => state.Authlogin?.isLoginWithGoogle
  );
  const isLoginWithMicrosoft = useSelector(
    (state: RootState) => state.Authlogin?.isLoginWithMicrosoft
  );

  useEffect(() => {
    if (isLoginWithGoogle || isLoginWithMicrosoft) {
      const fetchSession = async () => {
        try {
          // Fetch the session
          const {
            data: { session },
            error: sessionError,
          } = await supabase.auth.getSession();

          if (sessionError) {
            toast.error(`Error fetching session: ${sessionError.message}`);
            return;
          }

          if (session) {
            const email = session.user.user_metadata.email;

            const fullName =
              session.user.user_metadata.fullName ||
              session.user.user_metadata.name ||
              session.user.user_metadata.preferred_username; // Default value for full_name

            // Check if the email already exists in the database
            const { data: existingUser, error: fetchError } = await supabase
              .from("users")
              .select("email")
              .eq("email", email)
              .maybeSingle(); // Use maybeSingle to handle cases of zero or one row

            if (fetchError) {
              toast.error(
                `Error checking user existence: ${fetchError.message}`
              );
              return;
            }

            // If the user does not exist, insert a new row
            if (!existingUser) {
              const { data, error: insertError } = await supabase
                .from("users")
                .insert([
                  {
                    full_name: fullName, // Use default value if fullName is null
                    email: email,
                    role: ROLE.customer,
                  },
                ]);

              if (insertError) {
                toast.error(`Error inserting user: ${insertError.message}`);
              } else {
                toast.success("Sign-up successful!");
              }
            }
          }
        } catch (error) {
          // Type assertion to 'Error' or handle the unknown type more safely
          if (error instanceof Error) {
            toast.error(`Unexpected error: ${error.message}`);
          } else {
            toast.error(`Unexpected error occurred.`);
          }
        }
      };

      fetchSession();

      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (_, session) => {
          if (session) {
            navigate("/login");
            const { data } = await supabase
              .from("users")
              .select("*")
              .eq("email", session.user.email);
            const userData = data && data[0];
            dispatch(storeUserData({ session, ...userData }));
          }
        }
      );

      return () => {
        // Clean up the subscription using the 'unsubscribe' method of the subscription object
        authListener.subscription.unsubscribe();
      };
    }
  }, [isLoginWithGoogle, isLoginWithMicrosoft, dispatch, navigate]);

  return (
    <div className="h-screen">
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default PublicLayout;
