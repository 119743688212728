import { UserDetail } from "@/supabase/users";
import { createSlice } from "@reduxjs/toolkit";


export interface ApiClienData {
    id: string;
    clientID: string;
    contactID: string;
    billingName: string;
    name: string;
    firstName: string;
    lastName: string;
    status: string;
    clientType: string;
    primaryAddress: string;
    cachedata: any[]; // Adjust the type if you know the structure of items in the array
    latestNote: string;
    dateCreated: string; // Use number if it's a Unix timestamp, e.g., `dateCreated: number;`
    dateModified: string; // Use number if it's a Unix timestamp, e.g., `dateModified: number;`
    location?: string;
}


interface ClientState {
    clientList?: { [id: string]: ApiClienData };
}

const initialState: ClientState = {
    clientList: undefined,
};

const clientsSlice = createSlice({
    name: "clientSlice",
    initialState,
    reducers: {
        storeClientData: (state, action) => {
            state.clientList = action.payload;
        },
    },
});

export const { storeClientData } = clientsSlice.actions;
export default clientsSlice.reducer;
