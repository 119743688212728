import React, { useState, useEffect } from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { DeleteModal } from "@/components";
import { Table, Form, Input, Select, Button, Tooltip } from "antd";
import {
  SupabaseFormitizeUser,
  UserDetail,
  useUsersListing,
} from "@/supabase/users";
import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import { Delete, Info } from "@/utils/Images";
import { DeleteAdminModal } from "../AdminDetailsTable/deleteAdminModal/index";
import { toast } from "react-toastify";
import { StaffDetailsTableProps } from "@/models";

const { Option } = Select;

const EditableCell: React.FC<any> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  editing,
  formitizeUsers,
  ...restProps
}) => {
  let inputNode = <Input />;

  if (dataIndex === "role") {
    inputNode = (
      <Select style={{ maxHeight: "200px", overflowY: "auto" }}>
        <Option value={ROLE.admin}>Admin</Option>
        <Option value={ROLE.staff}>Staff</Option>
        <Option value={ROLE.customer}>Customer</Option>
      </Select>
    );
  } else if (dataIndex === "formitize_id") {
    inputNode = (
      <Select
        style={{ width: "200px", maxHeight: "200px", overflowY: "auto" }}
        allowClear
      >
        {formitizeUsers?.map((user: SupabaseFormitizeUser) => (
          <Option key={user.formitize_id} value={user.formitize_id}>
            {user.name} - {user.formitize_id}
          </Option>
        ))}
      </Select>
    );
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={
            dataIndex === "role" || dataIndex === "formitize_id"
              ? [{ required: true, message: `Please select ${title}!` }]
              : []
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const StaffDetailsTable: React.FC<StaffDetailsTableProps> = ({
  tableName,
  staffList,
}) => {
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [modalData, setModalData] = useState<UserDetail | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const { getUsersList, saveUserDetail } = useUsersListing();
  const formitizeUsers = useAppSelector(
    (state) => state.usersSlice.formitizeUsers
  );

  const isEditing = (record: UserDetail) => record.id === editingKey;

  const edit = (record: Partial<UserDetail>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id ?? "");
  };

  const cancel = () => {
    setEditingKey(null);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCancelDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onSave = async (key: string) => {
    try {
      const row = await form.validateFields();
      const newData = [...staffList];
      const index = newData.findIndex((item) => key === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        saveUserDetail(newData[index]);
        setEditingKey(null);
      } else {
        newData.push(row);
        setEditingKey(null);
      }
    } catch (errInfo) {
      toast.error("Something went wrong");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      editable: true,
    },
    {
      title: "Business Name",
      dataIndex: "abn",
      key: "abn",
      editable: true,
    },
    {
      title: "Business Number",
      dataIndex: "aus_number",
      key: "aus_number",
      editable: true,
    },
    {
      title: "Formitize Id",
      dataIndex: "formitize_id",
      key: "formitize_id",
      editable: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: UserDetail) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="flex">
            <Button
              onClick={() => onSave(record.id)}
              icon={<SaveOutlined />}
              style={{ marginRight: 8 }}
            >
              Save
            </Button>
            <Button onClick={cancel}>Cancel</Button>
          </span>
        ) : (
          <div className="flex items-center gap-2">
            <Button
              disabled={editingKey !== null}
              onClick={() => edit(record)}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <img
              src={Delete}
              alt=""
              className="w-6 h-6 max-w-6 cursor-pointer"
              onClick={() => {
                setModalData(record);
                handleOpenDeleteModal();
              }}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: UserDetail) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        formitizeUsers: formitizeUsers ?? [],
      }),
    };
  });

  const synDataAfterDelete = () => {
    getUsersList();
  };

  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      {openDeleteModal && (
        <DeleteAdminModal
          openDeleteModal={openDeleteModal}
          handleCancelDeleteModal={handleCancelDeleteModal}
          modalData={modalData}
          synDataAfterDelete={synDataAfterDelete}
        />
      )}
      <div className="flex items-center justify-between mb-6">
        <h4 className="text-xl font-semibold text-black flex items-center gap-2">
          {tableName}
          <Tooltip
            title="You can link SSA Assigned Staff accounts with Formitize accounts via the Formitize Id field"
            overlayStyle={{
              width: "max-content",
              maxWidth: "none",
              paddingLeft: "15px",
            }}
          >
            <span className="border-1 rounded-full flex items-center">
              <img src={Info} alt="" className="w-4 h-4" />
            </span>
          </Tooltip>
        </h4>
      </div>
      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          dataSource={staffList}
          rowClassName="cursor-pointer"
          scroll={{ x: "auto" }}
          className="custom-table"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </div>
  );
};

export { StaffDetailsTable };
