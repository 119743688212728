import { createSlice } from "@reduxjs/toolkit";

interface JobsState {
  jobFormList: any;
}

const initialState: JobsState = {
  jobFormList: [],
};

const jobFormListSlice = createSlice({
  name: "jobFormListSlice",
  initialState,
  reducers: {
    storeRequestJobs: (state, actions) => {
      state.jobFormList = actions.payload;
    },
  },
});

export const { storeRequestJobs } = jobFormListSlice.actions;
export default jobFormListSlice.reducer;


