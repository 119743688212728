import { createSlice } from "@reduxjs/toolkit";

export interface Job {
    id: string;
    billingName: string;
    contactName: string;
    jobNumber: string;
    title: string;
    location: string;
    dueDate: string;
    assignedTo: string;
    status: string;
    confirmed: string;
    siteName: string;
    zone: string;
    jobType: string;
    jobTitle: string;
    // orderNumber: string;
    description: string;
    // assignTo: string;
    endDate: string;
    orderNumber: string;
    assignAssets: string;
    priority: string;
    crew: string;
    shift: string;
    form: any;
    extraCharges: string;
    dueTime?: string;
    endTime?: string;
    invoice?: string;
    jobValue?: string;
    salesPerson?: string;
    salesSource?: string;
    isDisplay?: boolean;
    invoiceNumber?: string;
    clientID: string
}

interface JobsState {
    jobs: Job[];
}

const initialState: JobsState = {
    jobs: [],
};

const jobSlice = createSlice({
    name: "jobSlice",
    initialState,
    reducers: {
        storeJobs: (state, actions) => {
            state.jobs = actions.payload;
        },
    },
});

export const { storeJobs } = jobSlice.actions;
export default jobSlice.reducer;
