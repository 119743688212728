import { ListingTable, totalJobsHeadings } from "@/components";
import { useAppSelector } from "@/redux/store/Store";
import {
  useClientListing,
  useFetchAllAssignAssets,
  useFetchAssets,
  useFetchAssignAssetsIds,
  useFetchCreatedJobs,
  useFetchRemainingJobColoumns,
  useJobListing,
} from "@/supabase/jobs";
import { useUsersListing } from "@/supabase/users";
import { storeAssets } from "@/redux/slices/StoreAssetsSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ROLE } from "@/utils/enums";
import { Job } from "@/redux/slices/JobsSlice";
import { DashboardJobDetail } from "@/models";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const [assetsListing, setAssetsListing] = useState([]);
  const { fetchAssets } = useFetchAssets();
  const { fetchCreatedJobs } = useFetchCreatedJobs();
  const [createdJobListing, setCreatedJobsListing] = useState([]); //State to store Job listing from supabase
  const { fetchJobData } = useJobListing();
  const { getFormitizeUsers } = useUsersListing();
  const { fetchAssignAssetsIds } = useFetchAssignAssetsIds();
  const { fetchAllAssignAssets } = useFetchAllAssignAssets();
  const userData = useAppSelector((state) => state.Authlogin);
  const [assignAssetsIds, setAssignAssetsIds] = React.useState([]);
  const [assetsLoader, setAssetsLoader] = React.useState(false);
  const [remainingJobColumns, setremainingJobColumns] = React.useState<
    DashboardJobDetail[]
  >([]);
  const { fetchRemainingJobColoumns } = useFetchRemainingJobColoumns();
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const { fetchClientsData } = useClientListing();
  const jobListing = useAppSelector((state) => state.jobSlice.jobs); //Job listing from formitize
  const searchText = useAppSelector((state) => state.usersSlice.searchText);
  const profileData = useAppSelector((state) => state.Authlogin.data);
  const customerJobs = jobListing?.filter(
    (item) => item.billingName == userData?.data?.customer_formitize_id
  );

  const [jobList, setJobList] = useState<Job[]>([]);

  const staffJobs = jobListing?.filter(
    (job) => job?.assignedTo === profileData?.formitize_id
  );
  const mergedJobListing = [...createdJobListing, ...jobListing]?.map((job) => {
    const matchingColumn = remainingJobColumns?.find(
      (column: any) => column?.compared_id == job?.id
    );
    return matchingColumn
      ? {
          ...job,
          crew: matchingColumn.crew || "",
          assignAssets: matchingColumn.truck || "",
          shift: matchingColumn.shift || "",
          extraCharges: matchingColumn.extraCharges || "",
          contactName: matchingColumn.contactName,
          jobColumnId: matchingColumn.id,
        }
      : job;
  });
  const mergedCustomerJobListing = customerJobs?.map((job: any) => {
    const matchingColumn = remainingJobColumns?.find(
      (column: any) => column?.compared_id == job?.id
    );
    return matchingColumn
      ? {
          ...job,
          crew: matchingColumn.crew || "",
          assignAssets: matchingColumn.truck || "",
          shift: matchingColumn.shift || "",
          extraCharges: matchingColumn.extraCharges || "",
          contactName: matchingColumn.contactName,
          jobColumnId: matchingColumn.id,
        }
      : job;
  });
  const filteredJobListing = searchText
    ? mergedJobListing?.filter(
        (job) =>
          job?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
          (typeof job?.id === "string" &&
            job?.id?.toLowerCase().startsWith(searchText.toLowerCase()))
      )
    : mergedJobListing;

  const filterCustomerListing = searchText
    ? mergedCustomerJobListing?.filter(
        (job) =>
          job?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
          (typeof job?.id === "string" &&
            job?.id?.toLowerCase().startsWith(searchText.toLowerCase()))
      )
    : mergedCustomerJobListing;

  const filterStaffListing = searchText
    ? staffJobs?.filter(
        (job) =>
          job?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
          (typeof job?.id === "string" &&
            job?.id?.toLowerCase().startsWith(searchText.toLowerCase()))
      )
    : staffJobs;

  const getJobListings = () => {
    if (role == ROLE.customer) {
      setJobList(mergedCustomerJobListing);
    } else if (role == ROLE.staff) {
      setJobList(staffJobs);
    } else {
      setJobList(filteredJobListing);
    }
  };

  const fetchAndAssignAssets = async () => {
    // Step 1: Fetch all asset IDs and set them
    await fetchAssignAssetsIds(setAssignAssetsIds, setAssetsLoader);
  };

  useEffect(() => {
    getJobListings();
  }, [role, searchText]);

  React.useEffect(() => {
    fetchCreatedJobs(setCreatedJobsListing);
    fetchAndAssignAssets();
    fetchAssets(setAssetsListing);
  }, []);

  const fetchAllAssetsData = async () => {
    if (assignAssetsIds.length > 0) {
      // Step 2: Fetch all assets using Promise.all
      const fetchedAssets = await Promise.all(
        assignAssetsIds?.map(async (id) => {
          const params = new URLSearchParams();
          params.append("schema_id", id);
          const queryString = params.toString();
          return await fetchAllAssignAssets(queryString);
        })
      );

      // Step 3: Combine the results into a single array and set it in state
      const combinedData = [...fetchedAssets.flat(), ...assetsListing];
      dispatch(storeAssets(combinedData));
      setAssetsLoader(false);
    }
  };

  React.useEffect(() => {
    fetchAllAssetsData();
  }, [assignAssetsIds]);

  React.useEffect(() => {
    fetchClientsData();
    fetchJobData();
    getFormitizeUsers();
  }, []);

  React.useEffect(() => {
    fetchRemainingJobColoumns(setremainingJobColumns);
  }, [jobListing]);

  return (
    <div className="bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <ListingTable
        tableName="Total Jobs"
        createdJobListing={createdJobListing}
        tableHeader={totalJobsHeadings}
        tableData={
          role == ROLE.admin
            ? filteredJobListing
            : role == ROLE.customer
            ? filterCustomerListing
            : role == ROLE.staff
            ? filterStaffListing
            : filteredJobListing
        }
        isButton={true}
        buttonText={"Create"}
        onSyncClick={() => {
          fetchJobData();
          fetchAllAssetsData();
          fetchCreatedJobs(setCreatedJobsListing);
          fetchAndAssignAssets();
          fetchAssets(setAssetsListing);
        }}
      />
    </div>
  );
};

export default Dashboard;
