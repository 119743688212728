import { OpenSideNav } from "@/redux/slices/SignupSlice";
import { RootState } from "@/redux/store/Store";
import { Sidebar } from "@/components";
import { Header } from "@/components/Header";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const PrivateLayout = () => {
  const dispatch = useDispatch();
  const handleClickAway = () => {
    dispatch(OpenSideNav(false));
  };

  const isSidebar = useSelector((state: RootState) => state.Authlogin?.sideNav);
  return (
    <div className="flex">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div id="sidebar" className="relative">
          <Sidebar />
          <div
            className="fixed z-200 top-7 left-6 w-6 h-6 cursor-pointer"
            onClick={() => dispatch(OpenSideNav(!isSidebar))}
          ></div>
        </div>
      </ClickAwayListener>
      <div className="w-full relative">
        <div className="sticky top-0 z-99">
          <Header />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default PrivateLayout;
