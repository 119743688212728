import { hideLoader, showLoader } from "@/redux/slices/LoaderSlice";
import { useAppDispatch } from "@/redux/store/Store";
import { usefetchRequestJob } from "@/supabase/jobs";
import React from "react";
import { JobRequestTable } from "./JobRequestTable";

const JobRequest = () => {
  const dispatch = useAppDispatch();

  const handleShowLoader = () => {
    dispatch(showLoader());
  };
  const handleHideLoader = () => {
    dispatch(hideLoader());
  };

  const [requestJobListing, setRequestJobListing] = React.useState([]);
  const { fetchRequestJobs } = usefetchRequestJob();
  const handleFetchData = () => {
    fetchRequestJobs(setRequestJobListing, handleShowLoader, handleHideLoader);
  };
  React.useEffect(() => {
    handleFetchData();
  }, []);
  return (
    <JobRequestTable
      tableName="Job Request"
      tableData={requestJobListing}
      handleFetchData={handleFetchData}
    />
  );
};

export { JobRequest };
