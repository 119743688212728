import { AssignOrderNumProps } from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import {
  useUpdateColumnQuickly,
  useUpdateCreatedJobs,
  useUpdateRemainingJobColumn,
} from "@/supabase/jobs";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDebounceFunctions } from "../debounce";

const AssignOrderNum: React.FC<AssignOrderNumProps> = ({
  values,
  record,
  isOrderNumEditable,
  setIsOrderNumEditable,
  onSyncClick,
}) => {
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const [updateOrderNum, setUpdateOrdernum] = useState<string | null>(null);
  const { updateColumnQuickly } = useUpdateColumnQuickly();
  const { updateCreatedJobs } = useUpdateCreatedJobs();
  const { debouncedUpdateColumnQuickly, debouncedUpdateCreatedJobs } =
    useDebounceFunctions();

  const handleUpdateAssignTo = (record: any) => {
    const formIds = Object.values(record?.forms || {})?.map(
      (form: any) => form.id
    );

    const dueDate = new Date(record?.dueDate * 1000);
    const endDate = new Date(
      dueDate.getTime() + parseInt(record?.duration) * 1000
    );
    const endDateTimestamp = Math.floor(endDate.getTime() / 1000);

    let obj = {
      client: {
        billingName: record?.billingName || "",
      },
      title: record?.title || "",
      location: record?.location || "",
      dueDate: record?.dueDate || "",
      agent: record?.assignedTo || [],
      orderNumber: updateOrderNum || record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: record?.title || "",
      jobTitle: record?.title || "",
      notes: record?.description || "",
      form: formIds || "",
      endDate: endDateTimestamp,
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    let supabaseObj = {
      billingName: record?.billingName || "",
      title: record?.title || "",
      location: record?.location || "",
      dueDate: record?.dueDate || "",
      assignedTo: record?.assignedTo || [],
      orderNumber: updateOrderNum || record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: record?.title || "",
      jobTitle: record?.title || "",
      description: record?.description || "",
      form: record?.form || "",
      endDate: record?.endDate,
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    let newKeysObj = {
      extraCharges: record?.extraCharges || "",
      shift: record?.shift || "",
      contactName: record?.contactName || "",
      assignAssets: record?.assignAssets || [],
    };
    {
      !record?.source
        ? debouncedUpdateColumnQuickly(
            obj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsOrderNumEditable
          )
        : debouncedUpdateCreatedJobs(
            supabaseObj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsOrderNumEditable
          );
    }
  };

  return (
    <div>
      {isOrderNumEditable == record?.id && isOrderNumEditable ? (
        <div className="flex items-center gap-2">
          <input
            defaultValue={values}
            placeholder="Enter Client P.O."
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setUpdateOrdernum(e?.target?.value);
            }}
            className="w-full h-10 px-2 rounded-lg font-  medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
          />
          <div className="flex items-center gap-2">
            <CloseOutlined
              onClick={(e) => {
                setIsOrderNumEditable(null);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleUpdateAssignTo(record);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center whitespace-nowrap gap-2 ${
            values ? "justify-between" : "justify-end"
          }`}
        >
          <span>{values}</span>
          {role != ROLE.customer && role != ROLE.staff && (
            <EditOutlined
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOrderNumEditable(record?.id);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AssignOrderNum;
