// auth/SignupSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface RowState {
    isRowAnimation: string;
}

const initialState: RowState = {
    isRowAnimation: "",
};

const ShowRowAnimation = createSlice({
    name: "ShowRowAnimation",
    initialState,
    reducers: {
        showRowAnimation: (state, action) => {
            state.isRowAnimation = action.payload;
        },
    },
});

export const { showRowAnimation } = ShowRowAnimation.actions;
export default ShowRowAnimation.reducer;
