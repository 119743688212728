import { useAppSelector } from "@/redux/store/Store";
import { useUsersListing } from "@/supabase/users";
import { Info } from "@/utils/Images";
import { Table, Tooltip } from "antd";
import React, { useEffect } from "react";

export const FormitizeUserTable = () => {
  const { getFormitizeUsers } = useUsersListing();

  useEffect(() => {
    getFormitizeUsers();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Formitize ID",
      dataIndex: "formitize_id",
      key: "formitize_id",
    },
    {
      title: "Super Admin",
      dataIndex: "super_admin",
      key: "super_admin",
    },
    {
      title: "User Groups",
      dataIndex: "user_groups",
      key: "user_groups",
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
    },
    {
      title: "Username",
      dataIndex: "user_name",
      key: "user_name",
    },
  ];

  const formitizeUsers = useAppSelector(
    (state) => state.usersSlice.formitizeUsers
  );

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex items-center justify-between mb-6">
        <h4 className="text-xl font-semibold text-black flex items-center gap-2">
          Formitize Staff
          <Tooltip
            title="You can update Formitize Users by exporting the users csv from Formitize and uploading the data through the Upload Users button"
            overlayStyle={{
              width: "max-content",
              maxWidth: "none",
              paddingLeft: "15px",
            }}
          >
            <span className="border-1 rounded-full flex items-center">
              <img src={Info} alt="" className="w-4 h-4" />
            </span>
          </Tooltip>
        </h4>
      </div>
      <Table
        columns={columns}
        dataSource={formitizeUsers}
        rowClassName="cursor-pointer"
        scroll={{ x: "auto" }}
        className="custom-table"
      />
    </div>
  );
};
