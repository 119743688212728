import {
  AssignJobTitleClientOption,
  AssignJobTitleProps,
  AssignQuickJobTitle,
} from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import {
  usefetchJobTitleListing,
  useFetchNewCustomers,
  useUpdateColumnQuickly,
  useUpdateCreatedJobs,
  useUpdateRemainingJobColumn,
} from "@/supabase/jobs";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDebounceFunctions } from "../debounce";

const AssignJobTitle: React.FC<AssignJobTitleProps> = ({
  values,
  record,
  isJobTitleEditable,
  setIsJobTitleEditable,
  onSyncClick,
}) => {
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const { updateColumnQuickly } = useUpdateColumnQuickly();
  const { fetchJobTitleListing } = usefetchJobTitleListing();
  const [jobTitleListing, setJobTitleListing] = useState<AssignQuickJobTitle[]>(
    []
  );
  const { updateCreatedJobs } = useUpdateCreatedJobs();
  const [UpdateTitle, setUpdateTitle] =
    useState<AssignJobTitleClientOption | null>(null);
  const { debouncedUpdateColumnQuickly, debouncedUpdateCreatedJobs } =
    useDebounceFunctions();
  const { fetchNewCustomers } = useFetchNewCustomers();
  const formIdString = UpdateTitle?.formId
    ?.map((id: any) => `"${id}"`)
    .join(", ");
  const handleUpdateAssignTo = (record: any) => {
    const dueDateTimestamp = parseInt(record?.dueDate, 10);
    const durationInSeconds = parseInt(record?.duration, 10);

    // Calculate the final due date with duration
    const finalTimestamp = dueDateTimestamp + durationInSeconds;
    const formIds = Object.values(record?.forms || {})?.map(
      (form: any) => form.id
    );
    const driversIndex = record?.description?.indexOf("Drivers:") || -1;
    const drivers =
      driversIndex !== -1
        ? record?.description
            ?.slice(driversIndex + 8)
            .split("\n")[0]
            .trim() // Extract and clean the Drivers line
        : "";
    const notesIndex = record?.description?.indexOf("Notes:") || -1;
    const notes =
      notesIndex !== -1
        ? record?.description?.slice(notesIndex + 6).trim() // Extract text after "Notes:"
        : "";

    const dueDate = new Date(record?.dueDate * 1000);
    // Calculate the end date by adding the duration (in seconds)
    const endDate = new Date(
      dueDate.getTime() + parseInt(record?.duration) * 1000
    );
    const endDateTimestamp = Math.floor(endDate.getTime() / 1000);

    let obj = {
      client: {
        billingName: record?.billingName || "",
      },
      title: UpdateTitle?.value || "",
      location: record?.location || "",
      dueDate: dueDateTimestamp || "",
      agent: record?.assignedTo || [],
      orderNumber: record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: UpdateTitle?.value || "",
      jobTitle: UpdateTitle?.value || "",
      notes: record?.description || "",
      // crew: record?.crew || jobData?.crew || "",
      form: UpdateTitle?.formId || "",
      endDate: endDateTimestamp,
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    let newKeysObj = {
      extraCharges: record?.extraCharges || "",
      shift: record?.shift || "",
      contactName: record?.contactName || "",
      assignAssets: record?.assignAssets || [],
    };
    let supabaseObj = {
      billingName: record?.billingName || "",
      title: UpdateTitle?.value || "",
      location: record?.location || "",
      dueDate: record?.dueDate || "",
      assignedTo: record?.assignedTo || [],
      orderNumber: record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: UpdateTitle?.value || "",
      jobTitle: UpdateTitle?.value || "",
      description: record?.description || "",
      // crew: record?.crew || jobData?.crew || "",
      form: UpdateTitle?.formId || "",
      endDate: record?.endDate || "",
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    {
      !record?.source
        ? debouncedUpdateColumnQuickly(
            obj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsJobTitleEditable
          )
        : debouncedUpdateCreatedJobs(
            supabaseObj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsJobTitleEditable
          );
    }
  };

  React.useEffect(() => {
    fetchJobTitleListing(setJobTitleListing);
  }, []);
  return (
    <div>
      {isJobTitleEditable == record?.id && isJobTitleEditable ? (
        <div className="flex items-center gap-2">
          <Select
            allowClear
            showSearch
            value={UpdateTitle?.value?.toString() || values}
            style={{
              width: "206px",
              height: "48px",
            }}
            onChange={(e: string, data: any) => setUpdateTitle(data)}
            placeholder="Please select"
            options={jobTitleListing}
            optionFilterProp="label" // Filters based on the 'label' property in options
            filterOption={(input, option: any) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
          <div className="flex items-center gap-2">
            <CloseOutlined
              onClick={(e) => {
                setIsJobTitleEditable(null);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleUpdateAssignTo(record);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center whitespace-nowrap gap-2 ${
            values ? "justify-between" : "justify-end"
          }`}
        >
          <Tooltip title={values}>
            <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
              {values}
            </span>
          </Tooltip>
          {role != ROLE.customer && role != ROLE.staff && (
            <EditOutlined
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsJobTitleEditable(record?.id);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AssignJobTitle;
