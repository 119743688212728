import { CustomerDetailDeleteUserProps } from "@/models";
import { useAuth } from "@/supabase/auth";
import { Modal } from "antd";
import React from "react";

const DeleteUserModal: React.FC<CustomerDetailDeleteUserProps> = ({
  openDeleteModal,
  handleCancelDeleteModal,
  modalData,
  synDataAfterDelete,
}) => {
  const { deleteUser } = useAuth();
  const handleDeleteUser = () => {
    deleteUser(modalData?.id, handleCancelDeleteModal, synDataAfterDelete);
  };
  return (
    <div>
      <Modal
        title="Delete User"
        open={openDeleteModal}
        onOk={handleCancelDeleteModal}
        onCancel={handleCancelDeleteModal}
        footer={false}
      >
        <p className="font-medium text-base text-center mt-8">
          Are you sure you want to delete {modalData?.full_name} from
          application?
        </p>
        <div className="flex gap-4 mt-4">
          <button
            className="bg-blue-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-blue-700"
            onClick={() => handleDeleteUser()}
          >
            Delete
          </button>
          <button
            className="bg-red-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-red-700"
            onClick={handleCancelDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export { DeleteUserModal };
