import { SupabaseFormitizeUser, UserDetail } from "@/supabase/users";
import { createSlice } from "@reduxjs/toolkit";

interface UserState {
    userList: UserDetail[];
    searchText: string | null;
    formitizeUsers: SupabaseFormitizeUser[]
    profileImage?: string | null;
}

const initialState: UserState = {
    userList: [],
    searchText: null,
    formitizeUsers: [],
    profileImage: null,
};

const usersSlice = createSlice({
    name: "usersSlice",
    initialState,
    reducers: {
        storeUserData: (state, action) => {
            state.userList = action.payload;
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload
        },
        storeFormitizeUsers: (state, action) => {
            state.formitizeUsers = action.payload
        },
        setProfileImage: (state, action) => {
            state.profileImage = action.payload
        }
    },
});

export const { storeUserData, setSearchText, storeFormitizeUsers, setProfileImage } = usersSlice.actions;
export default usersSlice.reducer;
