import { Button } from "@/components";
import { Modal } from "antd";
import React from "react";
import { useDeleteNewCustomer } from "@/supabase/jobs";
import { DeleteClientModalProps } from "@/models";

const DeleteClientModal: React.FC<DeleteClientModalProps> = ({
  deleteModal,
  handleCancelDelete,
  deleteModalData,
  handleFetchNewCustomers,
  reloadDashboard,
}) => {
  const { deleteNewCustomer } = useDeleteNewCustomer();
  const handleDeleteClient = () => {
    deleteNewCustomer(
      deleteModalData?.value,
      handleFetchNewCustomers,
      reloadDashboard,
      handleCancelDelete
    );
  };
  return (
    <div>
      <Modal
        title="Delete Client"
        open={deleteModal}
        onOk={handleCancelDelete}
        onCancel={handleCancelDelete}
        footer={false}
      >
        <p className="font-medium text-base text-center mt-8">
          Are you sure you want to delete {deleteModalData?.name} from
          application?
        </p>
        <div className="flex gap-4 mt-4">
          <button
            className="bg-blue-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-blue-700"
            onClick={() => handleDeleteClient()}
          >
            Delete
          </button>
          <button
            className="bg-red-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-red-700"
            onClick={handleCancelDelete}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export { DeleteClientModal };
