import { AssignRequestAssetsProps } from "@/models";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import React from "react";

const AssignRequestAssets: React.FC<AssignRequestAssetsProps> = ({
  isTruckEditable,
  record,
  updatedAssets,
  setUpdatedAssets,
  setIsTruckEditable,
  handleUpdateAssignTo,
  values,
  role,
  transformedArray,
}) => {
  React.useEffect(() => {
    if (values) {
      setUpdatedAssets(values); // Update updatedAssets based on values
    }
  }, [values]);
  return (
    <div>
      <span>
        {isTruckEditable == record?.id && isTruckEditable ? (
          <div className="flex items-center gap-2">
            <Select
              allowClear
              mode="multiple"
              maxTagCount={1}
              // loading={sourceLoader}
              value={updatedAssets}
              style={{
                width: "100%",
                height: "48px",
              }}
              onChange={(_: any, data: any) => {
                setUpdatedAssets(data);
              }}
              placeholder="Please select"
              filterOption={(input, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={transformedArray}
            />
            <div className="flex items-center gap-2">
              <CloseOutlined
                onClick={(e) => {
                  if (values?.length > 0) {
                    setIsTruckEditable(null);
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    setIsTruckEditable(null);
                    setUpdatedAssets([]);
                  }
                }}
              />
              <CheckOutlined
                onClick={(e) => {
                  handleUpdateAssignTo(record, "assignAssets");
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
        ) : (
          <span
            className={`flex items-center whitespace-nowrap gap-2 ${
              values ? "justify-between" : "justify-end"
            }`}
          >
            <Tooltip
              title={
                Array.isArray(values)
                  ? values.filter(Boolean).join(" - ")
                  : JSON.parse(values || "[]")
                      .filter(Boolean)
                      .join(" - ")
              }
            >
              <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-60">
                {Array.isArray(values)
                  ? values.filter(Boolean).join(", ")
                  : JSON.parse(values || "[]")
                      .filter(Boolean)
                      .join(", ")}
              </span>
            </Tooltip>
            {role != ROLE.customer && role != ROLE.staff && (
              <EditOutlined
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsTruckEditable(record?.id);
                }}
              />
            )}
          </span>
        )}
      </span>
    </div>
  );
};

export default AssignRequestAssets;
