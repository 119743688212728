import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import PublicLayout from "../layouts/PublicLayout";
import { useSelector } from "react-redux";
import { RootState, useAppSelector } from "@/redux/store/Store";
import { PublicPath } from "./PublicPath";
import {
  PrivateAdminPath,
  PrivateCustomerPath,
  PrivateStaffPath,
} from "./PrivatePath";
import PrivateLayout from "@/layouts/PrivateLayout";
import { ROLE } from "@/utils/enums";

interface RouteProps {
  isAuthenticated: boolean | undefined;
}

const Allroutes = () => {
  const token = useSelector(
    (state: RootState) =>
      state.Authlogin.data?.session?.access_token ||
      state.Authlogin?.data?.user?.email
  );

  const userRole = useAppSelector((state) => state.Authlogin.data?.role);
  const isAdmin = userRole === ROLE.admin;
  const isStaff = userRole === ROLE.staff;

  const PublicRoute: React.FC<RouteProps> = ({ isAuthenticated }) => {
    if (isAuthenticated) {
      return <Navigate to="/jobs" replace />;
    }
    return <Outlet />;
  };

  const PrivateRoute: React.FC<RouteProps> = ({ isAuthenticated }) => {
    if (!isAuthenticated) return <Navigate to="/" replace />;
    return <Outlet />;
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicRoute isAuthenticated={!!token} />}>
          <Route path="/" element={<PublicLayout />}>
            {PublicPath?.map((routes, index) => (
              <Route
                key={index}
                path={routes.path}
                element={routes.component}
              />
            ))}
          </Route>
        </Route>

        {/* Private Routes */}
        <Route element={<PrivateRoute isAuthenticated={!!token} />}>
          <Route path="/" element={<PrivateLayout />}>
            {(isAdmin
              ? PrivateAdminPath
              : isStaff
              ? PrivateStaffPath
              : PrivateCustomerPath
            )?.map((routes, index) => {
              return (
                <Route
                  key={index}
                  path={`${routes.path}`}
                  element={routes.component}
                />
              );
            })}
          </Route>
        </Route>

        {/* Redirect Not Found to "/" */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Allroutes;
